import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  ipRoot: string = 'https://icitysolutions.icitybackend.com/api/v1';
  ipRootImg: string = 'http://10.147.17.46:701/';
  constructor() { }
}
