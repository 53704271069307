<ngx-spinner
  bdColor="rgba(255, 255, 255)"
  size="medium"
  color="#ED6B2D"
  z-index="999999999999999999999999999"
  template="<img src='../assets/loader.gif' />"
>
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner>

<!-- <app-error-message></app-error-message>  -->

<!-- <i class="pi pi-check"></i>  -->


<router-outlet></router-outlet>
