import { NgModule , APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';
import { SharedService } from './shared/shared.service';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslationService } from './shared/translation.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { RouterModule  } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


//set english language is the main lang in app
export function setupTranslateFactory(
  service: TranslationService): Function {
  return () =>  service.use('ar');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,


    AppRoutingModule,
    RouterModule.forRoot(
      [
        {path:"" , component:LoaderComponent},
      ],{scrollPositionRestoration: 'enabled'}
    ),
    NgbModule
  ],

  providers: [SharedService,
    TranslationService,
    // { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    {
     provide: APP_INITIALIZER,
     useFactory: setupTranslateFactory,
     deps: [ TranslationService ],
     multi: true
   },
   {provide: LocationStrategy, useClass:  HashLocationStrategy},],
  bootstrap: [AppComponent]
})
export class AppModule { }
